import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import personalData from "../../Constants/personalData";
import moment from "moment";
import { Media } from "reactstrap";

import "./style.css";

class Experience extends Component {
  getDuration(dur) {
    const years = parseInt(dur / 12);
    const months = dur > 12 ? dur % 12 : dur;
    const duration =
      (years > 0 ? years + " year" + (years > 1 ? "s" : "") + " and " : "") +
      (months > 0 ? months + " month" + (months > 1 ? "s" : "") : "");
    return duration;
  }

  render() {
    return (
      <Container>
        <Row>
          <Col>
            <section>
              {personalData.experiences.map((experience, i) => {
                moment.locale("en");

                const totalDuration = experience.roles.reduce(function(
                  cnt,
                  role
                ) {
                  const startDate = moment(role.startDate);
                  const timeEnd = moment(
                    role.currentJob ? new Date() : new Date(role.endDate)
                  );
                  const duration = moment.duration(timeEnd.diff(startDate));
                  return (
                    Number(cnt) + Number(duration.asMonths().toPrecision(1))
                  );
                },
                0);

                return (
                  <div key={i}>
                    <Media>
                      <Media left top href={experience.url} target="_blank">
                        <figure>
                          <img
                            src={experience.logo}
                            alt={experience.companyName}
                          />
                        </figure>
                      </Media>
                      <Media body>
                        <Media heading>
                          <a
                            href={experience.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {experience.companyName}
                          </a>
                          <span className="jobTotalDuration">
                            {this.getDuration(totalDuration)}
                          </span>
                        </Media>

                        {experience.roles.map((role, i) => {
                          const startDate = moment(role.startDate);
                          const timeEnd = moment(
                            role.currentJob
                              ? new Date()
                              : new Date(role.endDate)
                          );
                          const duration = Number(
                            moment
                              .duration(timeEnd.diff(startDate))
                              .asMonths()
                              .toPrecision(1)
                          );

                          return (
                            <div key={i}>
                              <h5>{role.title}</h5>
                              <span className="jobDuration">
                                {startDate.format("MMM YYYY")} -{" "}
                                {role.currentJob
                                  ? "Present"
                                  : timeEnd.format("MMM YYYY")}{" "}
                                ({this.getDuration(duration)})
                              </span>
                              <span className="jobLocation">
                                {role.location}
                              </span>
                              <p className="jobDescription">
                                {role.description}
                              </p>
                            </div>
                          );
                        })}
                      </Media>
                    </Media>
                  </div>
                );
              })}
            </section>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Experience;
