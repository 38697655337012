import React from 'react';
import {Jumbotron, Container} from "reactstrap";

const AboutHeader = () => (
    <section>
        <Jumbotron>
            <Container>
                <h1 className="display-3">Mobile Application Developer</h1>
                <p className="lead">I am passionate about about all things software, on handheld or wearable devices. I like experimenting with new technologies and software developement fields. In my free time I like to dance, watch movies and practice my English with video games.</p>
            </Container>
        </Jumbotron>
    </section>
);

export default AboutHeader;