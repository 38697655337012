import app from 'firebase/app';
import 'firebase/firestore';
import firebaseConfig from '../Constants/firebaseConfig';

class Firebase {
    constructor() {
        if (!app.apps.length) {
            app.initializeApp(firebaseConfig);
        }
        this.db = app.firestore();
    }

    async sendMessage(name, email, subject, message) {
        const now = new Date();
        const datetimeString = now.getFullYear()+'-'+(now.getMonth()+1)+'-'+now.getDate()+' '+now.getHours() + ":" + now.getMinutes() + ":" + now.getSeconds();
        
        const data = {
            name,
            email,
            subject,
            message
        };
        return this.db.collection('messages').doc(datetimeString).set(data);
    }
}

export default Firebase;