import React, { Component } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from 'mdbreact';
import Firebase from '../../Firebase/firebase';

class ContactForm extends Component {

    validLabel = "form-control";
    invalidLabel = "form-control is-invalid";

    constructor(props) {
        super(props);

        this.firebase = new Firebase();

        this.state = {
            nameLabelValid: this.validLabel,
            emailLabelValid: this.validLabel,
            subjectLabelValid: this.validLabel,
            messageLabelValid: this.validLabel,
            name: '',
            email: '',
            subject: '',
            message: '',
            displaySuccess: 'none'
        }
    }

    validateFields() {
        let validFields = true;

        if(this.state.name.length === 0) {
            this.setState({nameLabelValid: this.invalidLabel});
            validFields = false;
        } else {
            this.setState({nameLabelValid: this.validLabel});
        }

        if(this.isEmailValid(this.state.email) === false) {
            this.setState({emailLabelValid: this.invalidLabel});
            validFields = false;
        } else {
            this.setState({emailLabelValid: this.validLabel});
        }

        if(this.state.subject.length === 0) {
            this.setState({subjectLabelValid: this.invalidLabel});
            validFields = false;
        } else {
            this.setState({subjectLabelValid: this.validLabel});
        }

        if(this.state.message.length === 0) {
            this.setState({messageLabelValid: this.invalidLabel});
            validFields = false;
        } else {
            this.setState({messageLabelValid: this.validLabel});
        }

        return validFields;
    }

    isEmailValid(email) {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    resetForm() {
        this.setState({
            nameLabelValid: this.validLabel,
            emailLabelValid: this.validLabel,
            subjectLabelValid: this.validLabel,
            messageLabelValid: this.validLabel,
            name: '',
            email: '',
            subject: '',
            message: '',
        });
    }

    hideSuccessMessage() {
        this.setState({displaySuccess: 'none'});
    }

    showSuccessMessage() {
        this.setState({displaySuccess: 'block'});
        setTimeout(() => {
            this.hideSuccessMessage();
        }, 5000);
    }

    sendMessage() {
        if(this.validateFields() === true) {
            
            const {
                name, email, subject, message
            } = this.state;

            this.firebase.sendMessage(name, email, subject, message).then(() => {
                this.showSuccessMessage();
                this.resetForm();
            });
        }
        
    }

    render() {
        const {
            name,
            email,
            subject,
            message,
            nameLabelValid,
            emailLabelValid,
            subjectLabelValid,
            messageLabelValid,
            displaySuccess
        } = this.state;
        return (
            <MDBContainer>
              <MDBRow style={{ justifyContent: 'flex-end' }}>
                <MDBCol md="6">
                  <form>
                    <p className="h2 text-center mb-4">Have an idea or you want to know more? Leave a message!</p>
                    <p className="h6 text-center mb-4" style={{color: 'green', display: displaySuccess }}>Your message has been sent.</p>
                    <label htmlFor="defaultFormContactNameEx" className="grey-text">
                      Your name
                    </label>
                    <input
                      type="text"
                      id="defaultFormContactNameEx"
                      className={nameLabelValid}
                      value={name}
                      onChange={(event) => {
                          this.setState({name: event.target.value})
                      }}
                    />
                    <br />
                    <label htmlFor="defaultFormContactEmailEx" className="grey-text">
                      Your email
                    </label>
                    <input
                      type="email"
                      id="defaultFormContactEmailEx"
                      className={emailLabelValid}
                      value={email}
                      onChange={(event) => {
                        this.setState({email: event.target.value})
                    }}
                    />
                    <br />
                    <label
                      htmlFor="defaultFormContactSubjectEx"
                      className="grey-text"
                    >
                      Subject
                    </label>
                    <input
                      type="text"
                      id="defaultFormContactSubjectEx"
                      className={subjectLabelValid}
                      value={subject}
                      onChange={(event) => {
                        this.setState({subject: event.target.value})
                    }}
                    />
                    <br />
                    <label
                      htmlFor="defaultFormContactMessageEx"
                      className="grey-text"
                    >
                      Your message
                    </label>
                    <textarea
                      type="text"
                      id="defaultFormContactMessageEx"
                      className={messageLabelValid}
                      value={message}
                      onChange={(event) => {
                        this.setState({message: event.target.value})
                    }}
                      rows="3"
                    />
                    <div className="text-center mt-4">
                    <MDBBtn outline color="info" onClick={() => {
                        this.sendMessage();
                    }}>
                        Send
                      </MDBBtn>
                    </div>
                  </form>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          );
    }
  
};

export default ContactForm;