import React from "react";
import { Component } from "react";
import {
  Container,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink
} from "reactstrap";
import classnames from "classnames";
import Helmet from "react-helmet";
import AboutHeader from "../../Components/AboutHeader";
import Experience from "../../Components/Experience";
import Education from "../../Components/Education";

import "./style.css";

class AboutPage extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "1"
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  render() {
    return (
      <div>
        <AboutHeader />
        <Container>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "1" })}
                onClick={() => {
                  this.toggle("1");
                }}
              >
                <b className="tabName">Experience</b>
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "2" })}
                onClick={() => {
                  this.toggle("2");
                }}
              >
                <b className="tabName">Education</b>
              </NavLink>
            </NavItem>
          </Nav>

          <Helmet>
            <title>About</title>
            <meta
              name="description"
              content="Education and experience in software development."
            />
          </Helmet>
          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId="1">
              <Experience />
            </TabPane>

            <TabPane tabId="2">
              <Education />
            </TabPane>
          </TabContent>
        </Container>
      </div>
    );
  }
}

export default AboutPage;
