import React, { Component } from "react";
import { Container, Row, Col, Media } from "reactstrap";
import moment from "moment";
import personalData from "../../Constants/personalData";

class Education extends Component {
  render() {
    return (
      <Container>
        <Row>
          <Col>
            <section>
              {personalData.studies.map((study, i) => {
                return (
                  <Media key={i}>
                    <Media
                      left
                      top
                      href={study.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <figure>
                        <Media object src={study.logo} alt={study.institute} />
                      </figure>
                    </Media>
                    <Media body>
                      <Media heading>
                        <a
                          href={study.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {study.institute}
                        </a>
                      </Media>
                      {[
                        {
                          key: "Title",
                          value: study.title
                        },
                        {
                          key: "Graduation Year",
                          value: study.graduationYear
                        },
                        {
                          key: "Duration",
                          value: study.durationInYears + " year(s)"
                        }
                      ].map((object, i) => {
                        return (
                          <div>
                            <Row>
                              <Col className="formLabel">{object.key}:</Col>
                            </Row>
                            <Row>
                              <Col>{object.value}</Col>
                            </Row>
                          </div>
                        );
                      })}
                    </Media>
                  </Media>
                );
              })}
            </section>
          </Col>
        </Row>
        <br />
        <br />
        <Row>
          <Col>
            <section>
              <h4>Certificates:</h4>
              <hr />
              {personalData.certifications.map((certification, i) => {
                const verification = certification.verificationLink ? (
                  <Row>
                    <Col>
                      <a
                        className="certificateLink"
                        href={certification.verificationLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        See certificate
                      </a>
                    </Col>
                  </Row>
                ) : (
                  ""
                );
                return (
                  <Media key={i}>
                    <Media left top href={certification.url}>
                      <figure>
                        <Media
                          object
                          src={certification.logo}
                          alt={certification.title}
                        />
                      </figure>
                    </Media>
                    <Media body>
                      <Media heading>
                        <a
                          href={certification.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {certification.title}
                        </a>
                      </Media>
                      <Row>
                        <Col>
                          {moment(certification.issueDate).format(
                            "DD MMM YYYY"
                          )}
                          {certification.expiryDate
                            ? " - " +
                              moment(certification.expiryDate).format(
                                "DD MMM YYYY"
                              )
                            : ""}
                        </Col>
                      </Row>
                      <Row>
                        <Col>{certification.issuer}</Col>
                      </Row>
                      {verification}
                    </Media>
                  </Media>
                );
              })}
            </section>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Education;
