const firebaseConfig = {
    apiKey: "AIzaSyCXuWKv5RLN9IfbegaGpd7dJP1Eb9YDMXc",
    authDomain: "elodkocsiswebsite.firebaseapp.com",
    databaseURL: "https://elodkocsiswebsite.firebaseio.com",
    projectId: "elodkocsiswebsite",
    storageBucket: "",
    messagingSenderId: "120718303611",
    appId: "1:120718303611:web:4e725efbbf8285fe"
  };

export default firebaseConfig;