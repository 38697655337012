import React from 'react';
import { Component } from 'react';
import Helmet from 'react-helmet';

import './style.css';

class HomePage extends Component {
    render() {
        return (
            <main id="home">
                <Helmet>
                    <title>Előd Kocsis's Website</title>
                    <meta name="description" content="Welcome to Előd's Website." />
                </Helmet>
                <div className="row banner">
                    <div className="banner-text">
                    <h1 className="responsive-headline">Hi! I am Előd Kocsis.</h1>
                    <h3 style={{color:'#fff', fontFamily:'sans-serif '}}>
                        I am a mobile application developer and university student struggling with React and this website.
                        Take a look around.
                    </h3>
                    <hr/>
                    </div>
                </div>

                <p className="scrolldown">
                    <a className="smoothscroll" href="#about"><i className="icon-down-circle"></i></a>
                </p>
            </main>
        );
    }
}

export default HomePage;
