import React from "react";
import { Component } from "react";
import { Helmet } from "react-helmet";
import ContactForm from "../../Components/ContactForm";

import "./style.css";

class ContactPage extends Component {
  render() {
    return (
      <div className="root">
        <Helmet>
          <title>Contact</title>
          <meta
            name="description"
            content="Have an idea or you want to know more? Contact me."
          />
        </Helmet>
        <section>
          <ContactForm />
        </section>
      </div>
    );
  }
}

export default ContactPage;
