import React from "react";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import HomePage from "../Home";
import AboutPage from "../About";
import ContactPage from "../Contact";
import NotFound from "../404";
import * as Routes from "../../Constants/routes";

import "./style.css";

const Main = () => {
  return (
    <Router>
      <nav id="nav-wrap">
        <ul id="nav" className="nav">
          <li>
            <Link className="smoothscroll" to={Routes.HOME}>
              Home
            </Link>
          </li>
          <li>
            <Link className="smoothscroll" to={Routes.ABOUT}>
              About
            </Link>
          </li>
          <li>
            <Link className="smoothscroll" to={Routes.CONTACT}>
              Contact me
            </Link>
          </li>
        </ul>
      </nav>
      <div>
        <Switch>
          <Route exact path={Routes.HOME} component={HomePage} />
          <Route path={Routes.ABOUT} component={AboutPage} />
          <Route path={Routes.CONTACT} component={ContactPage} />
          <Route component={NotFound} />
        </Switch>
      </div>
    </Router>
  );
};

export default Main;
